<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>退款/售后</span></div>
			<div class="search_area">
				<div>
					退款编号：
					<input type="text" placeholder="请输入退款编号" v-model="order_no">
					下单时间：
					<el-date-picker @change="changeTimeLimit" v-model="date_limit" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<div class="btn" @click="refresh">搜索</div>
				</div>
			</div>
			<div v-loading="loading">
				<nav>
					<li>商品信息</li>
					<li>退款金额</li>
					<li>退款类型</li>
					<li>退款状态</li>
					<li>操作</li>
				</nav>

				<div class="list" v-if="refundList && refundList.length > 0">
					<div class="item" v-for="(refundItem, refundIndex) in refundList" :key="refundIndex">
						<div class="head">
							<span class="create-time">{{ $util.timeStampTurnTime(refundItem.refund_action_time) }}</span>
							<span class="order-no">退款编号：{{ refundItem.refund_no }}</span>
							<router-link :to="'/shop-' + refundItem.site_id" target="_blank">{{ refundItem.site_name }}</router-link>
							<span class="order-type">{{ refundItem.refund_status == 3 ? '退款成功' : '退款中' }}</span>
							<span class="order-type">下单人：{{ refundItem.nickname }}</span>
						</div>
						<ul >
							<li v-if="refundItem.refund_type == 2">
								<div class="" v-for="(goodsItem,goodsIndex) in refundItem.order_goods" :key="goodsIndex">
									<div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)"><img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(refundIndex)" /></div>
									<div class="info-wrap">
										<h5 @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">{{ goodsItem.sku_name }}</h5>
										<div class="num">
											x{{goodsItem.apply_num}}
										</div>
									</div>
								</div>
							</li>
							<li v-else>
								仅退款无商品信息
							</li>
							<li>
								<span>￥{{ refundItem.refund_apply_money }}</span>
							</li>

							<li>
								<span>{{ refundItem.refund_type_name }}</span>
							</li>
							<li>
								<span class="ns-text-color">{{ refundItem.refund_status_name }}</span>
								<!-- <el-link :underline="false" @click="orderDetail(refundItem)">退款详情</el-link> -->
							</li>
							<li>
								<el-button
									type="primary"
									size="mini"
									:plain="true"
									@click="orderDetail(refundItem)"
								>
									退款详情
								</el-button>
								<template v-if="refundItem.refund_status_action && JSON.parse(refundItem.refund_status_action).member_action.length > 0">
									<el-button
										type="primary"
										size="mini"
										:plain="true"
										v-for="(operationItem, operationIndex) in JSON.parse(refundItem.refund_status_action).member_action"
										:key="operationIndex"
										@click="clickoperati(refundItem,operationItem)"
									>
										{{ operationItem.title }}
									</el-button>
								</template>
							</li>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && refundList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div class="pager">
				<el-pagination
					background
					:pager-count="5"
					:total="total"
					prev-text="上一页"
					next-text="下一页"
					:current-page.sync="currentPage"
					:page-size.sync="pageSize"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange"
					hide-on-single-page
				></el-pagination>
			</div>
		</el-card>
		<el-dialog :visible.sync="add_dialog" :show-close="false" class="add_dialog">
			<div class="add_content">
				<div class="tit">物流退货</div>
				<div class="condition">
					<div class="condition_item">
						<div class="left">
							*<div class="name">快递编号：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入快递编号" v-model="formData.refund_delivery_no">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">备注：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入备注" v-model="formData.refund_delivery_remark">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">物流公司：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入物流公司名称" v-model="formData.refund_delivery_name">
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="submit">保存</div>
					<div class="btn" @click="add_dialog = false">返回</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { refundList, cancleRefund ,returnRefund} from '@/api/order/refund';
export default {
	name: 'activist',
	components: {},
	data: () => {
		return {
			add_dialog:false,
			orderStatus: 'all',
			loading: true,
			order_no:'',
			refundList: [],
			date_limit:'',
			currentPage: 1,
			pageSize: 10,
			total: 0,
			yes: true,
			formData:{
				refund_delivery_name:'',
				refund_delivery_no:'',
				refund_delivery_remark:'',
				refund_id:'',
			}
		};
	},
	created() {
		this.orderStatus = this.$route.query.status || 'all';
		this.getrefundList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		changeTimeLimit(e){
			if(e){
				this.start_time = e[0];
				this.end_time = e[1];
			}
		},
		clickoperati(data,item){
			if(item.event == "orderRefundCancel"){
				this.$confirm("您确定要撤销维权吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					returnRefund({
						refund_id: data.refund_id
					})
					.then(res => {
						if (res.code >= 0) {
							this.$message({
								message: '撤销成功'
							})
							this.refresh();
						} else {
							this.$message.error(res.message)
						}
					})
				})
			}else{
				this.formData.refund_id = data.refund_id
				this.add_dialog = true
			}
		},
		submit(){
			if(!this.formData.refund_delivery_name){
				this.$message.error('物流公司名称不能为空');
				return false;
			}
			if(!this.formData.refund_delivery_no){
				this.$message.error('请输入物流编号');
				return false;
			}
			if(!this.formData.refund_delivery_remark){
				this.$message.error('请输入备注');
				return false;
			}
			cancleRefund(this.formData)
				.then(res => {
					let list = [];
					if (res.code == 0 && res.data) {
						this.$message({
							message: '操作成功',
						})
						this.refresh();
						
					}
					this.loading = false;
					this.add_dialog = false
				})
				.catch(res => {
					this.$message.error(res.message);
					this.loading = false;
				});
		},
		
		handleClick(tab, event) {
			this.currentPage = 1;
			this.orderStatus = tab.name;
			this.refresh();
		},
		getrefundList() {
			refundList({
				page: this.currentPage,
				page_size: this.pageSize,
				start_time:this.start_time,
				end_time:this.end_time,
				refund_no:this.order_no,
			})
				.then(res => {
					let list = [];
					if (res.code == 0 && res.data) {
						list = res.data.list;
						this.total = res.data.count;
					}
					this.refundList = list;
					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getrefundList();
		},
		operation(action, orderData) {
			switch (action) {
				case 'orderRefundCancel': // 撤销维权
					this.cancleRefund(orderData.order_goods_id);
					break;
				case 'orderRefundDelivery': // 退款发货
					this.$router.push({ path: '/member/refund_detail', query: { order_goods_id: orderData.order_goods_id, action: 'returngoods' } });
					break;
				case 'orderRefundAsk':
					this.$router.push({ path: '/member/refund?order_goods_id=' + orderData.order_goods_id });
					break;
			}
		},
		orderDetail(data) {
			console.log(data)
			this.$router.push({ path: '/member/refund_detail', query: { order_goods_id: data.refund_id } });
		},
		imageError(refundIndex) {
			this.refundList[refundIndex].sku_image = this.defaultGoodsImage;
		},

		cancleRefund(order_goods_id) {
			this.$confirm('撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请', '提示', {
				confirmButtonText: '确认撤销',
				cancelButtonText: '暂不撤销',
				type: 'warning'
			})
				.then(() => {
					if (this.isSub) return;
					this.isSub = true;

					cancleRefund({ order_goods_id: order_goods_id })
						.then(res => {
							const { code, message, data } = res;
							if (code >= 0) {
								this.$message({
									message: '撤销成功',
									type: 'success'
								});
								this.getrefundList();
							} else {
								this.$message({ message: message, type: 'warning' });
							}
						})
						.catch(err => {
							this.$message.error(err.message);
						});
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 45%;
			}
			&:nth-child(2) {
				width: 15%;
			}
			&:nth-child(3) {
				width: 10%;
			}
			&:nth-child(4) {
				width: 15%;
			}
			&:nth-child(5) {
				width: 15%;
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 15px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}

				.order-type {
					margin-left: 15px;
				}
			}
		}
		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			display: flex;
			align-items: center;
			li {
				float: left;
				line-height: 60px;
				&:nth-child(1) {
					width: 45%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						height: 65px;
						margin-left: 70px;
						.num{
							color: #959595;
						}
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							cursor: pointer;
							display: inline-block;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					width: 15%;
				}
				&:nth-child(3) {
					width: 10%;
				}
				&:nth-child(4) {
					width: 14%;
					line-height: 30px;
					a {
						display: block;
					}
				}
				&:nth-child(5) {
					width: 15%;
					line-height: initial;
					button {
						margin-left: 0;
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}
		.add_dialog {
			.el-dialog__body {
				padding: 0;

				.add_content {
					padding: 8px 44px 41px;

					.tit {
						color: #111;
						font-size: 16px;
						line-height: 22px;
						font-weight: 600;
						margin-bottom: 24px;
					}

					.condition {
						padding-left: 33px;
						.condition_item {
							display: flex;
							align-items: baseline;
							margin-bottom: 20px;
							.left {
								display: flex;
								align-items: center;
								color: #E02020;
								font-size: 12px;
								line-height: 16px;
								min-width: 70px;
								margin-right: 2px;
								justify-content: flex-end;
								.name {
									margin-left: 5px;
									color: #222;
									font-size: 12px;
									line-height: 16px;
								}
							}
							.right {
								flex: 1;
								input {
									width: 45%;
									outline: none;
									border: 1px solid #DBDCDC;
									padding: 8px 12px;
									font-size: 12px;
									line-height: 16px;
									color: #333;
									&::placeholder {
										color: #999;
									}
								}
								.el-tree{
									max-height: 430px;
									overflow: auto;
								}
							}
						}
					}

					.btns {
						margin-top: 12px;
						display: flex;
						align-items: center;
						padding-left: 104px;

						.btn {
							cursor: pointer;
							margin-right: 24px;
							color: #333;
							font-size: 14px;
							line-height: 16px;
							padding: 9px 20px;
							border-radius: 2px;
							border: 1px solid #DBDCDC;

							&.save {
								background-color: #007AFF;
								color: #fff;
							}
						}
					}
				}
			}
		}
		.search_area{
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			>div{
				display: flex;
				align-items: center;
				margin-bottom: 12px;
			}
			.el-date-editor{
				border: 1px solid #E0E0E0;
				border-radius: 0;
				height: auto;
				padding: 8px 10px;
				.el-range-separator{
					line-height: 20px;
				}
				.el-input__icon{
					line-height: 1;
				}
				.el-range-input{
					border: none;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					padding: 0;
				}
			}
			input{
				border: 1px solid #E0E0E0;
				outline: none;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 8px 12px;
				margin-right: 12px;
			}
			input::placeholder{
				color: #888;
				font-size: 14px;
				line-height: 20px;
			}
			.btn{
				color: #FFF;
				font-size: 12px;
				line-height: 16px;
				padding: 6px 18px;
				border-radius: 2px;
				background-color: #00A98F;
				margin-left: 10px;
				cursor: pointer;
			}
		}
		.search_area .el-date-editor{
			padding: 2px 12px !important;
		}
</style>
